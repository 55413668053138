import React from 'react'
import { Link } from 'gatsby'
import facebook from "../../images/facebook.svg"
import twitter from "../../images/twitter.svg"
import linkedin from "../../images/linkedin.svg"
import "./footer.scss"

const Footer = (props) => {
  const { copyright } = props

  return (
    <footer className='footer'>
      <div className="links">
        <ul className="groupLinks">
          <li><Link to='/about/'>About</Link></li>
          <li><Link to='/careers/'>Careers</Link></li>
        </ul>
        <ul className="groupLinks">
          <li><Link to='/contact/'>Contact Us</Link></li>
          <li><Link to='/services/'>Services</Link></li>
        </ul>
        <div className="groupLinks">
          <div className="social">
            <a href="/" rel="noreferrer" target="_blank">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/valley-commercial-service" rel="noreferrer" target="_blank">
              <img src={linkedin} alt="LinkedIn" />
            </a>
            <a href="/" rel="noreferrer" target="_blank">
              <img src={twitter} alt="Twitter" />
            </a>
          </div>
          <p>{copyright}</p>
          <address>
            627 N Wayne Ave<br />
            Cincinnati, OH 45215
          </address>
          <p>License Ohio: OH25130 Kentucky: MO1198</p>
          <p><a href="https://www.schrenk.dev/" target="_blank">WebSite by Branden Schrenk</a></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer